var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category bj" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addclick } },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportToExcel } },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", align: "center", label: "ID" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$empty.empty(scope.row.id)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "品类名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$empty.empty(scope.row.name)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_audit_gao",
                  align: "center",
                  label: "高碑店集配发品是否需要审核",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.is_audit_gao == 1 ? "是" : "否") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_audit_xiao",
                  align: "center",
                  label: "孝感集配发品是否需要审核",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.is_audit_xiao == 1 ? "是" : "否") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.selectda(
                                  scope.$index,
                                  scope.row,
                                  "parent"
                                )
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.selectda(
                                  scope.$index,
                                  scope.row,
                                  "add"
                                )
                              },
                            },
                          },
                          [_vm._v("添加子类")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.selectda(
                                  scope.$index,
                                  scope.row,
                                  "children"
                                )
                              },
                            },
                          },
                          [_vm._v("查看子类")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.seeOrigin(scope.row)
                              },
                            },
                          },
                          [_vm._v("产地 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "enterprise personindex" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: "查看子类",
                width: "70%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.tableVisible = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  staticClass: "row-box d-flex flex-column mt-20",
                  staticStyle: { height: "calc(100% - 64px)" },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.listData,
                        height: "100%",
                        border: true,
                        "header-cell-style": {
                          color: "#333333",
                          background: "#EFF6FF",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "100px",
                          align: "center",
                          label: "序号",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          width: "100px",
                          align: "center",
                          label: "ID",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", align: "center", label: "名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$empty.empty(scope.row.name)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "freight_parameter",
                          align: "center",
                          label: "运费系数(%)",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.freight_parameter) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "purchase_parameter",
                          align: "center",
                          label: "服务费系数(%)",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.purchase_parameter) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "品牌/品种",
                          prop: "tag",
                          minWidth: "100px",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "tag-wrap" }, [
                                  _c(
                                    "div",
                                    { staticClass: "tag-box" },
                                    _vm._l(scope.row.tags, function (child) {
                                      return _c(
                                        "span",
                                        { key: child.id, staticClass: "tag" },
                                        [_vm._v(_vm._s(child.name))]
                                      )
                                    }),
                                    0
                                  ),
                                  _c("span", { staticClass: "icon" }, [
                                    _c("i", {
                                      staticClass: "el-icon-edit-outline",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onHandleTag(scope.row)
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "排序",
                          prop: "listorder",
                          width: "100px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          "min-width": "130",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editChild(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleQrCode(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("小程序二维码")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.childDialogVisible
        ? _c(
            "el-dialog",
            {
              ref: "refChildCategoryDialog",
              staticClass: "dialog",
              attrs: {
                title: _vm.childTitle,
                visible: _vm.childDialogVisible,
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.childDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "childForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.childForm,
                        rules: _vm.childRules,
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "父级品类", prop: "pid" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    disabled: _vm.childDisabled,
                                    placeholder: "选择父级品类",
                                  },
                                  model: {
                                    value: _vm.childForm.pid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.childForm, "pid", $$v)
                                    },
                                    expression: "childForm.pid",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "品类名称", prop: "name" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入品类名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.childForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.childForm, "name", $$v)
                                  },
                                  expression: "childForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "运费系数(%)",
                            prop: "freight_parameter",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "inputs" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入运费",
                                  clearable: "",
                                },
                                on: { blur: _vm.freightBlur },
                                model: {
                                  value: _vm.childForm.freight_parameter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.childForm,
                                      "freight_parameter",
                                      $$v
                                    )
                                  },
                                  expression: "childForm.freight_parameter",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "服务费系数(%)",
                            prop: "purchase_parameter",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "inputs" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入服务费",
                                  clearable: "",
                                },
                                on: { blur: _vm.purchaseBlur },
                                model: {
                                  value: _vm.childForm.purchase_parameter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.childForm,
                                      "purchase_parameter",
                                      $$v
                                    )
                                  },
                                  expression: "childForm.purchase_parameter",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序", prop: "listorder" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入排序值",
                              maxlength: "5",
                              clearable: "",
                            },
                            model: {
                              value: _vm.childForm.listorder,
                              callback: function ($$v) {
                                _vm.$set(_vm.childForm, "listorder", $$v)
                              },
                              expression: "childForm.listorder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品详情默认图片",
                            prop: "detail_pic",
                          },
                        },
                        [
                          _c("b-file-upload", {
                            key: "detail_pic",
                            attrs: {
                              accepts: ["jpg", "jpeg", "png"],
                              limit: 1,
                            },
                            model: {
                              value: _vm.detailPicList,
                              callback: function ($$v) {
                                _vm.detailPicList = $$v
                              },
                              expression: "detailPicList",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品详情默认视频",
                            prop: "detail_video",
                          },
                        },
                        [
                          _c("b-file-upload", {
                            key: "detail_video",
                            attrs: {
                              accepts: ["mp4", "avi", "flv", "mov"],
                              limit: 1,
                            },
                            model: {
                              value: _vm.detailVideoList,
                              callback: function ($$v) {
                                _vm.detailVideoList = $$v
                              },
                              expression: "detailVideoList",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "禁售城市仓", prop: "logistic_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "logistic-select_wrap",
                              attrs: {
                                placeholder: "选择禁售城市仓",
                                loading: _vm.loadingCityStore,
                                multiple: "",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.onCityStore },
                              model: {
                                value: _vm.logisticIds,
                                callback: function ($$v) {
                                  _vm.logisticIds = $$v
                                },
                                expression: "logisticIds",
                              },
                            },
                            _vm._l(_vm.cityStoreList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "高碑店集配发品是否需要审核",
                            prop: "is_audit_gao",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.is_audit_gao == 1 ? true : false,
                              },
                              model: {
                                value: _vm.childForm.is_audit_gao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.childForm, "is_audit_gao", $$v)
                                },
                                expression: "childForm.is_audit_gao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "孝感集配发品是否需要审核",
                            prop: "is_audit_xiao",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.is_audit_xiao == 1 ? true : false,
                              },
                              model: {
                                value: _vm.childForm.is_audit_xiao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.childForm, "is_audit_xiao", $$v)
                                },
                                expression: "childForm.is_audit_xiao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.childDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.childConfirm },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: _vm.title,
                visible: _vm.centerDialogVisible,
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "160px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "父级品类", required: "" } },
                        [
                          _vm.title == "编辑品类"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        disabled: _vm.parentDisabled,
                                        placeholder: "选择父级品类",
                                      },
                                      model: {
                                        value: _vm.ruleForm.id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "id", $$v)
                                        },
                                        expression: "ruleForm.id",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入品类名称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "name", $$v)
                                      },
                                      expression: "ruleForm.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "是否首页显示", prop: "radius_pic" },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.flag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "flag", $$v)
                                    },
                                    expression: "ruleForm.flag",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      class: {
                                        hide: _vm.radius_pic.length == 1,
                                      },
                                      attrs: {
                                        action: "#",
                                        "on-preview":
                                          _vm.handlePictureCardPreview,
                                        "file-list": _vm.radius_pic,
                                        "list-type": "picture-card",
                                        "http-request": (file) => {
                                          return _vm.uploadFile(
                                            file,
                                            "radius_pic"
                                          )
                                        },
                                        "before-remove": (file, fileList) => {
                                          return _vm.deleteFile(
                                            file,
                                            fileList,
                                            "radius_pic"
                                          )
                                        },
                                        limit: 1,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-plus",
                                        attrs: { slot: "trigger" },
                                        slot: "trigger",
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "text-center" }, [
                                    _vm._v("显示图"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.ruleForm.id && _vm.ruleForm.is_audit_gao == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择招商", prop: "buyer_id" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "选择选择招商",
                                  },
                                  model: {
                                    value: _vm.ruleForm.buyer_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "buyer_id", $$v)
                                    },
                                    expression: "ruleForm.buyer_id",
                                  },
                                },
                                _vm._l(_vm.updatecategorylist, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.fullname,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "高碑店集配发品是否需要审核",
                            prop: "is_audit_gao",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.gaochang },
                              model: {
                                value: _vm.ruleForm.is_audit_gao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "is_audit_gao", $$v)
                                },
                                expression: "ruleForm.is_audit_gao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "孝感集配发品是否需要审核",
                            prop: "is_audit_xiao",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.is_audit_xiao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "is_audit_xiao", $$v)
                                },
                                expression: "ruleForm.is_audit_xiao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "果蔬", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "非果蔬", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showImage,
                    expression: "showImage",
                  },
                ],
                staticClass: "shadow",
                on: {
                  click: function ($event) {
                    _vm.showImage = false
                  },
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showImage,
                      expression: "showImage",
                    },
                  ],
                  staticClass: "bigImage",
                },
                [
                  _c("img", { attrs: { src: _vm.bigImage, alt: "" } }),
                  _c("i", {
                    staticClass: "el-icon el-icon-close bigClose",
                    on: {
                      click: function ($event) {
                        _vm.showImage = false
                      },
                    },
                  }),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addCategories },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("qr-code-dialog", { ref: "refQrCode" }),
      _c("goods-tag-dialog", {
        ref: "refGoodsTagDialog",
        on: { "on-change": _vm.ajaxGetCategories },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }